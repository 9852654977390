import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ApiImage } from '../../api/image';
import { Modal, ModalProps } from '../../RbKit';
import MediaListView from './';

export const openMediaModal = (callback: (image: ApiImage) => void) => {
    dispatchEvent(new CustomEvent('open-media-modal', { detail: { callback } }));
}

interface MediaViewModalProps {
    trigger?: JSX.Element,
}

const MediaViewModal: FC<MediaViewModalProps & ModalProps> = ({
    header,
    open,
    trigger,
    ...restProps
}): JSX.Element => {
    const [ triggerOpen, setTriggerOpen ] = useState(false);
    let onImageSelect: any = useRef();

    const handleImageSelect = useCallback((image: ApiImage) => {
        if (!onImageSelect.current) return;
        onImageSelect.current(image);
        setTriggerOpen(false);
    }, [onImageSelect]);

    useEffect(() => {
        window.addEventListener('open-media-modal', (e: any) => {
            setTriggerOpen(true);
            onImageSelect.current = e.detail.callback;
        });
        return () => window.removeEventListener('open-media-modal', (e: any) => {
            setTriggerOpen(true);
            onImageSelect.current = e.detail.callback;
        });
    }, []);

    return (<>
        {trigger && React.cloneElement(trigger, { onClick: () => setTriggerOpen(true) })}
        <Modal
            header={header || 'Select an image'}
            open={triggerOpen}
            onClose={() => setTriggerOpen(false)}
            {...restProps}
        >
            <MediaListView
                isModal={true}
                onImageSelect={(img) => handleImageSelect(img)}
            />
        </Modal>
    </>);
}

export default MediaViewModal;
