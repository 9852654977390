import { findIndex } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Form } from '../../RbKit';
import { ApiImage } from '../../api/image';
import MediaSelector from '../../components/MediaSelector';
import styles from './styles.module.scss';

interface SidebarProps {
    onClose: () => void,
    open: boolean,
}

const Sidebar: FC<SidebarProps> = ({ onClose, open }): JSX.Element => {
    const [ fields, setFields ] = useState<any[]>();

    useEffect(() => {
        window.addEventListener('set-editable-block', (e: any) => {setFields([...e.detail.block.fields])});
        return () => window.removeEventListener('set-editable-block', (e: any) => setFields([...e.detail.block.fields]));
    }, []);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        if (!fields) return;
        const index = findIndex(fields, { id: name });
        const newFields = [...fields];
        newFields[index].value = value;
        setFields(newFields);
    }

    return (<>
        <div
            className={[
                styles.sidebarOverlay,
                open && styles.show,
            ].join(' ')}
            onClick={() => onClose()}
        />
        <div
            className={[
                styles.sidebar,
                open && styles.show,
            ].join(' ')}
        >
            <div className={styles.sidebarTitle}>
                <h2>Edit block</h2>
            </div>
            {fields && fields.map((field: any, index: number) => {
                let input = null;

                if (field.type === 'text') {
                    input = (<Form.Input
                        label={field.label}
                        name={field.id}
                        onChange={handleInput}
                        value={field.value}
                    />);
                } else if (field.type === 'wysiwyg') {
                    input = (<Form.Group>
                        <label>{field.label}</label>
                        <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                            value={field.value}
                            init={{
                                height: 200,
                                max_height: 400,
                                menubar: false,
                                plugins: ['autoresize', 'lists', 'link'],
                                toolbar: 'bold italic underline | bullist numlist | link',
                                content_style: 'body, p { margin: 0; padding: 16px; font-size: 16px }',
                                autoresize_bottom_margin: 16,
                                force_br_newlines: true,
                            }}
                            onEditorChange={(c: string) => handleInput({ name: field.id, value: c })}
                        />
                    </Form.Group>);
                } else if (field.type === 'image') {
                    input = (<Form.Group>
                        <label>{field.label}</label>
                        <MediaSelector
                            onSelect={(img: ApiImage) => {
                                handleInput({ name: field.id, value: { id: img.id, src: img.src }});
                            }}
                            thumb={field.value}
                        />
                    </Form.Group>)
                } else if (field.type === 'dropdown') {
                    input = <Form.Dropdown
                        {...field.props}
                        label={field.label}
                        name={field.id}
                        onChange={handleInput}
                        value={field.value}
                    />
                }

                return <div
                    className={styles.sidebarField}
                    key={`sidebar-${index}`}
                >
                    {input}
                </div>
            })}
            <div className={styles.sidebarFooter}>
                <Button link label="Cancel" onClick={() => onClose()} />
                <Button primary label="Update" onClick={() => onClose()} />
            </div>
        </div>
    </>);
}

export default Sidebar;
