import React, { FC, useEffect, useState } from 'react';
import { request } from '../../api';
import { ApiPersona } from '../../api/persona';
import { Segment, Button } from '../../RbKit';
import SortableList, { reorder } from '../../components/SortableList';

interface SortModeProps {
    items: ApiPersona[],
    onCancel: () => void,
    onUpdate: (reordered?: ApiPersona[]) => void,
}

const SortMode: FC<SortModeProps> = ({ items, onCancel, onUpdate }): JSX.Element => {
    const [ sorted, setSorted ] = useState<ApiPersona[]>(items);
    useEffect(() => setSorted(items), [items]);

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            setSorted(reorder(sorted, result.source.index, result.destination.index));
        }
    }

    const handleSave = (): void => {
        request.post(`personas/sort`, { personas: sorted.map(o => o.id) }).then(() => {
            onUpdate(sorted);
        });
    }

    return (<>
        <div style={{ marginBottom: '1rem' }}>
            <Button
                label="Save"
                onClick={() => handleSave()}
                primary
            />
            <Button
                label="Cancel"
                onClick={onCancel}
            />
        </div>
        <SortableList
            items={sorted}
            onUpdate={onDragEnd}
            renderListItem={(item) => (
                <Segment padding="dense">
                    {item.name}
                </Segment>
            )}
        />
    </>);
}

export default SortMode;
