import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Checkbox, Form, PageHeader, Segment, toast } from '../../RbKit';
import api from '../../api';
import { ApiQuestion } from '../../api/question';
import ChangeHandler from '../../components/ChangeHandler';
import ContentEditor from '../../modules/ContentEditor';
import { ApiPersona } from '../../api/persona';
import TagInput from './TagInput';

interface QuestionEditProps extends RouteComponentProps<{ id?: string }> {
}

const QuestionEditView: FC<QuestionEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ question, setQuestion ] = useState<Partial<ApiQuestion>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ personas, setPersonas ] = useState<ApiPersona[]>();

    useEffect(() => {
        api.listPersonas({ limit: 999 }).then(({ data }) => {
            setPersonas(data.data);
        });
    }, []);
    
    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getQuestion(parseInt(id)).then(({ data }) => {
                setQuestion({
                    ...data,
                    personas: data.personas ? data.personas.map((o: ApiPersona) => o.id): [],
                });
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setQuestion({
            ...question,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putQuestion(question).then(({ data }) => {
            setIsLoading(false);
            setChangesMade(false);
            toast('Question saved succesfully');
            history.push(`/questions/${data.id}/edit`);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/questions': 'Questions',
                [`/questions/${id ? `${id}/edit` : 'create'}`]: id ? question.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} question`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Input
                    error={errors.name}
                    label="Question"
                    name="name"
                    onChange={handleInput}
                    required
                    value={question.name || ''}
                />
                <Form.Input
                    label="Promomatsnumber (internal)"
                    name="promomatsNumber"
                    onChange={handleInput}
                    value={question.promomatsNumber || ''}
                />
                {personas && <Form.Dropdown
                    label="Persona"
                    multiple
                    name="personas"
                    onChange={handleInput}
                    options={personas.map((o) => ({
                        text: o.name,
                        value: o.id,
                    }))}
                    value={question.personas || []}
                />}
                <Form.Group label="Searchterms" focus={true}>
                    <TagInput
                        onChange={(tags: string[]) => handleInput({ name: 'tags', value: tags })}
                        tags={question.tags}
                        type="question"
                    />
                </Form.Group>
                <Form.Group>
                    <Checkbox
                        checked={question.isSticky}
                        label="Set as sticky"
                        onChange={({ checked }: any) => handleInput({ name: 'isSticky', value: checked })}
                    />
                </Form.Group>
            </Segment>
            <ContentEditor
                blocks={question.content}
                onUpdate={(content) => {
                    setQuestion({
                        ...question,
                        content,
                    });
                    setChangesMade(true);
                }}
            />
        </Form>
    </>);
}

export default QuestionEditView;
