import React, { FC } from 'react';
import { ApiImage } from '../../api/image';
import ImageThumb from '../ImageThumb';
import { Button } from '../../RbKit';
import { openMediaModal } from '../../views/MediaView/Modal';

interface MediaSelectorProps {
    onSelect: (img: ApiImage) => void,
    thumb?: ApiImage,
}

const MediaSelector: FC<MediaSelectorProps> = ({ onSelect, thumb }): JSX.Element => {
    return (<>
        <div style={{ marginBottom: '1rem' }}>
            <ImageThumb
                square
                image={thumb}
            />
        </div>
        <Button
            label="Select an image"
            onClick={() => openMediaModal(onSelect)}
        />
    </>);
}

export default MediaSelector;
