import { faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../RbKit';
import api, { ApiMeta } from '../../api';
import { ApiAlert } from '../../api/alert';

const AlertListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ alerts, setAlerts ] = useState<ApiAlert[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listAlerts({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setAlerts(data.data);
            setIsLoading(false);
        });
    }

    const deleteAlert = (alertId: number): void => {
        api.deleteAlert(alertId).then(() => {
            fetch();
            toast('Alert deleted successfully');
        });
    }

    const updateStatus = (index: number): void => {
        const newAlerts = [...alerts];
        newAlerts[index].isActive = !newAlerts[index].isActive;
        setAlerts(newAlerts);
        setIsLoading(true);

        api.putAlert(newAlerts[index]).then(({ data }) => {
            setIsLoading(false);
            toast('Alert updated');
        });
    }

    return (<>
        <PageHeader title="Alerts" breadcrumb={{'/alerts': 'Overview'}}>
            <Button
                href="/alerts/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>

        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Alert
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="center">
                            Is active
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {alerts.length > 0 ? alerts.map((alert, index) => (
                        <Table.Row key={`row-${alert.id}`}>
                            <Table.Cell>
                                <Link to={`/alerts/${alert.id}/edit`}>
                                    {alert.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell align="center" collapsing>
                                <Checkbox
                                    checked={alert.isActive}
                                    onChange={() => updateStatus(index)}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/alerts/${alert.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Are you sure you wish to delete this alert? This action cannot be undone"
                                        onConfirm={() => deleteAlert(alert.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default AlertListView;
