import { faPlus, faPencil, faTrashAlt, faRetweet } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../RbKit';
import { ApiMeta, request } from '../../api';

const PersonaListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ logs, setLogs ] = useState<any[]>([]);
    const [ query, setQuery ] = useState<string>('');

    const fetch = (q?: string, page: number = 1): void => {
        setIsLoading(true);

        if (q) {
            setQuery(q);
        }

        request.get(`admin/search-logs?query=${q || query}&page=${page || 1}`).then(({ data }) => {
            setMeta(data.meta);
            setLogs(data.data);
            setIsLoading(false);
        });
    }

    return (<>
        <PageHeader title="Search log" breadcrumb={{'/search-log': 'Overview'}} />
        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Query
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            #
                        </Table.HeaderCell>
                    </Table.Row>
                </thead>
                <tbody>
                    {logs.length > 0 ? logs.map((log, index) => (
                        <Table.Row key={`row-${index}`}>
                            <Table.Cell>
                                {log.query}
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {log.cnt}
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch(query, page)}
        />}
    </>);
}

export default PersonaListView;
