import { faUpload } from '@fortawesome/pro-light-svg-icons';
import React, { FC, RefObject, useState } from 'react';
import api, { ApiMeta } from '../../api';
import { ApiImage } from '../../api/image';
import { Button, PageHeader, Progress, Segment, Table, toast } from '../../RbKit';
import ImageThumb from '../../components/ImageThumb';
import styles from './styles.module.scss';

interface UploadButtonProps {
    onUpload: (e: any) => void,
}

interface MediaViewProps {
    isModal?: boolean,
    onImageSelect?: (image: ApiImage) => void,
}

export const UploadButton:FC<UploadButtonProps> = ({ onUpload }) => {
    const fileInputRef: RefObject<HTMLInputElement> = React.createRef();

    return (<>
        <Button
            icon={faUpload}
            onClick={() => fileInputRef.current?.click()}
            primary
        />
        <input
            multiple
            onChange={onUpload}
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
            accept="image/*"
        />
    </>);
}

const MediaView: FC<MediaViewProps> = ({ isModal, onImageSelect }) => {
    const [ images, setImages ] = useState<ApiImage[]>([]);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ remQuery, setQuery ] = useState<string>('');
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const [ uploadProgress, setUploadProgress ] = useState<number>(0);
    const [ totalUploadProgress, setTotalUploadProgress ] = useState<number>(0);

    const handleDelete = (imageId: number): void => {
        api.deleteImage(imageId).then(() => {
            toast('Image deleted succesfully');
            setImages([...images.filter(o => o.id !== imageId)]);
        });
    }

    const handleSearch = (query?: string, page?: number): void => {
        setIsLoading(true);
        if (query) {
            setQuery(query);
        }

        api.listImages({ query: query || remQuery, page: page || 1 }).then(({ data }) => {
            setIsLoading(false);
            setImages(page === 1 ? data.data : [...images, ...data.data]);
            setMeta(data.meta);
        });
    }

    const handleFileUpload = (e: any): void => {
        setIsUploading(true);
        uploadFile(e.target.files, 0, e.target.files.length);
    }

    const handleSelect = (image: ApiImage) => {
        if (onImageSelect) {
            onImageSelect(image);
        }
    }

    const uploadFile = (files: FileList, index: number, max: number): void => {
        setTotalUploadProgress(index / max);
        setUploadProgress(0);

        if (index >= max) {
            setIsUploading(false);
            return;
        }

        api.uploadImage(files[index], (progressEvent: any) => {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }).then(({ data }) => {
            setImages([data, ...images]);
            uploadFile(files, index + 1, max);
        });
    }

    return (<>
        {!isModal && (<PageHeader
            breadcrumb={{'media/images': 'Media'}}
            title="Images"
        >
            <UploadButton onUpload={(e: any) => handleFileUpload(e)} />
        </PageHeader>)}
        
        {isUploading && <Segment>
            <Progress progress={uploadProgress} style={{ marginBottom: '.5rem' }} />
            <Progress progress={totalUploadProgress * 100} />
        </Segment>}

        {isModal ? (
            <div style={{ marginBottom:  '1rem' }}>
                <Table.Actions autoLoad onSearch={handleSearch} />
            </div>
        ) : (
            <Segment isLoading={isLoading}>
                <Table.Actions autoLoad onSearch={handleSearch} />
            </Segment>
        )}

        <div className={styles.container}>
            {images.length > 0 && images.map((image, index) => (
                <div
                    key={index}
                    onClick={() => handleSelect(image)}
                    style={onImageSelect ? { cursor: 'pointer' } : {}}
                >
                    <ImageThumb
                        onDelete={onImageSelect ? undefined : handleDelete}
                        image={image}
                        square
                    />
                </div>
            ))}
            <div style={{ clear: 'both' }} />
        </div>
        <div style={{ clear: 'both' }} />

        {meta && <Table.Pagination
            infinite
            meta={meta}
            onChange={(page) => handleSearch(undefined, page)}
        />}

        {isModal && <div className={styles.modalUploadButton}>
            <UploadButton onUpload={(e: any) => handleFileUpload(e)} />
        </div>}
    </>);
}

export default MediaView;
