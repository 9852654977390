import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import api from '../../../api';
import { Button, Form, Segment, toast } from '../../../RbKit';
import styles from './styles.module.scss';

const LoginView: FC = (): JSX.Element => {
    const [ emailAddress, setEmailAddress ] = useState<string>('');
    const [ error, setError ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        const token = qs.parse(window.location.search.substring(1));
        
		if (token && token._setToken) {
            setIsLoading(true);
            localStorage.setItem('accessToken', token._setToken as string);
            api.getProfile().then(() => {
                history.push('/questions');
            });
		}

        document.querySelector('input')?.focus();
    }, [history]);

    const login = (): void => {
        if (!emailAddress) {
            toast('Please fill in an email address', 'error');
            setError(true);
            return;
        }

        setError(false);
        setIsLoading(true);
        
        api.login(emailAddress).then(() => {
            setIsLoading(false);
            toast('A magic link has been sent');
        }).catch(() => {
            setError(true);
            setIsLoading(false);
        });
    }

    return (
        <div className={styles.container}>
            <div>
                <Segment isLoading={isLoading} elevate={1}>
                    <h2>Request a login link</h2>
                    <p>
                        Enter your email address below and we'll send you a magic link to log you in to Application name!
                    </p>
                    <Form.Input
                        error={error}
                        label="Email address"
                        onChange={({ value }: any) => setEmailAddress(value)}
                        value={emailAddress}
                    />
                    <div className={styles.btnContainer}>
                        <Button
                            label="Send me the link"
                            onClick={() => login()}
                            primary
                        />
                    </div>
                </Segment>
            </div>
        </div>
    );
}

export default LoginView;
