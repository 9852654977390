import { faPencil, faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faGripHorizontal } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Segment } from '../../RbKit';
import styles from './styles.module.scss';

interface BlockContainerProps {
    handle?: any
    onDelete?: () => void,
    onEdit?: () => void,
    onPlus?: () => void,
}

const BlockContainer: FC<BlockContainerProps> = ({
    children,
    handle,
    onDelete,
    onEdit,
    onPlus
}): JSX.Element => {
    return (
        <div className={styles.blockContainer}>
            <Segment
                className={styles.blockContainerActions}
                elevate={2}
                padding="none"
            >
                {handle && <span {...handle} style={{ cursor: 'move' }}>
                    <FontAwesomeIcon icon={faGripHorizontal} />
                </span>}
                {onEdit && <span onClick={() => onEdit()}>
                    <FontAwesomeIcon icon={faPencil} />
                </span>}
                {onPlus && <span onClick={() => onPlus()}>
                    <FontAwesomeIcon
                        icon={faPlus}
                    />
                </span>}
                {onDelete && <span onClick={() => onDelete()}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </span>}
            </Segment>
            <div className={styles.blockContainerContent}>
                {children}
            </div>
        </div>
    );
}

export default BlockContainer;
