import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, PageHeader, Segment, toast } from '../../RbKit';
import api from '../../api';
import { ApiPersona } from '../../api/persona';
import ChangeHandler from '../../components/ChangeHandler';
import MediaSelector from '../../components/MediaSelector';
import { ApiImage } from '../../api/image';

interface PersonaEditProps extends RouteComponentProps<{ id?: string }> {
}

const PersonaEditView: FC<PersonaEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ persona, setPersona ] = useState<Partial<ApiPersona>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ image, setImage ] = useState<ApiImage>();
    useEffect(() => {
        if (!image) {
            setImage(persona.image);
        }
    }, [persona]);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getPersona(parseInt(id)).then(({ data }) => {
                setPersona(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setPersona({
            ...persona,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putPersona(persona).then(({ data }) => {
            setIsLoading(false);
            setChangesMade(false);
            toast('Persona saved succesfully');
            history.push(`/personas/${data.id}/edit`);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/personas': 'Personas',
                [`/personas/${id ? `${id}/edit` : 'create'}`]: id ? persona.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} persona`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Input
                    error={errors.name}
                    label="Persona"
                    name="name"
                    onChange={handleInput}
                    required
                    value={persona.name || ''}
                />
                <Form.Group>
                    <label>Image/Icon</label>
                    <MediaSelector
                        onSelect={(img: ApiImage) => {
                            handleInput({ name: 'imageId', value: img.id });
                            setImage(img);
                            setChangesMade(true);
                        }}
                        thumb={image}
                    />
                </Form.Group>
            </Segment>
        </Form>
    </>);
}

export default PersonaEditView;
