import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import api from '../../../api';
import  { Input, InputProps } from '../../../RbKit';
import { useHandleKeyUp, useDebounceSearch } from '../../../RbKit/lib/hooks';
import styles from './styles.module.scss';

interface TagInputProps {
    onChange: (tags: string[]) => void,
    tags?: string[],
    type: string,
}

const TagInput: FC<TagInputProps & InputProps> = ({ onChange, tags, type, ...rest }): JSX.Element => {
    const [ tag, setTag ] = useState<string>('');
    const [ _tags, setTags] = useState<string[]>(tags || []);
    useEffect(() => setTags(tags || []), [tags]);

    const addTag = (newTag: string) => {
        const newTags = [
            ..._tags,
            newTag.replace(/[^A-Za-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-')
        ];

        setTags(newTags);
        onChange(newTags);
    }

    const removeTag = (index: number) => {
        const newTags = _tags.filter((o, i) => i !== index);
        setTags(newTags);
        onChange(newTags);
    }
    
    const debounceSuggest = useDebounceSearch((query: string) => {
        if (query !== '') {
            api.suggestTag(query, type).then(({ data }) => {
                if (data === '') return;
                setTag(data);
                const input = document.querySelector('#tag-suggest input') as HTMLInputElement;
                input?.setSelectionRange(query.length, data.length);
            });
        }
    }, 300);

    useHandleKeyUp(() => {
        if (tag !== '') {
            addTag(tag);
            setTag('');
        }
    }, [13]);

    return (
        <div id="tag-suggest">
            <Input
                onChange={({ value }: any) => {
                    setTag(value)
                    debounceSuggest(value);
                }}
                placeholder="Type and enter"
                value={tag}
                {...rest}
            />
            <div>
                {_tags.map((t: string, index: number) => (
                    <span
                        className={styles.tag}
                        key={index}
                        onClick={() => removeTag(index)}
                    >
                        {t}
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                ))}
            </div>
        </div>
    );
}

export default TagInput;
