import { faPlus, faPencil, faTrashAlt, faThumbsDown, faThumbsUp, faShareAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../RbKit';
import api, { ApiMeta } from '../../api';
import { ApiQuestion } from '../../api/question';

const QuestionListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ questions, setQuestions ] = useState<ApiQuestion[]>([]);
    const [ sortMode, setSortMode ] = useState<boolean>(false);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listQuestions({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setQuestions(data.data);
            setIsLoading(false);
        });
    }

    const deleteQuestion = (questionId: number | string): void => {
        api.deleteQuestion(questionId).then(() => {
            fetch();
            toast('Question deleted successfully');
        });
    }

    return (<>
        <PageHeader title="Questions" breadcrumb={{'/questions': 'Overview'}}>
            <Button
                href="/questions/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>

        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Question
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            Hits
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            align="right"
                            collapsing
                            icon={faThumbsUp}
                        />
                        <Table.HeaderCell
                            align="right"
                            collapsing
                            icon={faThumbsDown}
                        />
                        <Table.HeaderCell collapsing>
                            Promomatsnumber
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {questions.length > 0 ? questions.map((question) => (
                        <Table.Row key={`row-${question.id}`}>
                            <Table.Cell>
                                <Link to={`/questions/${question.id}/edit`}>
                                    {question.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {question.hits}
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {question.thumbsUp || 0}
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {question.thumbsDown || 0}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {question.promomatsNumber || '-'}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button
                                    href={`https://www.roche.nl/nl/contact.html?qid=${question.id}`}
                                    target="_blank"
                                    icon={faShareAlt}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/questions/${question.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Are you sure you wish to delete this question? This action cannot be undone"
                                        onConfirm={() => deleteQuestion(question.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default QuestionListView;
